import { getCurrentYear } from "../utils/globalFunction";

const modules = [
  {
    value: "Баҳисобгирии музди меҳнат",
    chapters: [
      {
        value: "«Тақсимоти маблағҳои» суратҳисоби буҷа  Документ 1",
        path: "/payroll_accounting/document1",
      },
      {
        path: "/payroll_accounting/document2",
        value: "«Тақсимоти маблағҳои» суратҳисоби махсус  Документ 2",
      },
      {
        value: "«Таъминоти пулӣ» Документ 3",
        path: "/payroll_accounting/document3",
      },
      {
        value: "«Шаҳодатномаи пулӣ» Документ 4",
        path: "/payroll_accounting/document4",
      },
      {
        value: "«Ҳисоби №» Документ 5",
        path: "/payroll_accounting/document5",
      },
      {
        value:
          "«Справочник статей расходов с соответствующими бухгалтерскими записями» Документ 6",
        path: "/payroll_accounting/document6",
      },
      {
        value: "«Феҳристи ҷузъу томҳо» Документ 7 ",
        path: "/payroll_accounting/document7",
      },
    ],
  },
  {
    value: "Пардохти музди меҳнат",
    chapters: [
      { value: "«Феҳристи пардохт» - Документ 8" },
      { value: "«Платежное поручение (Супоришномаи пулӣ)» Документ 9" },
      {
        value:
          "Кафолатномаи ташкилоти буҷетӣ барои анҷом додани маблағгузори СХМ ВМ» Документ 10",
      },
      { value: "«Варақаи электронии пардохт»  Документ 11" },
    ],
  },
  {
    value: "Финансовая отчетность",
    chapters: [
      { value: "«Ҳисоботи андоз» - Документ №12" },
      { value: "«Ҳисоботи АҲИА» - Документ 13" },
      { value: "«Ҳисоботи омор» - Документ №14" },
      { value: "«Сводный отчет о расходах по статьям» Документ 28 " },
      { value: "«Ведемость по ЗП» Документ 29" },
      { value: "«Справка о доходах» Документ 31" },
      { value: "«Расчетный листок» Документ 32" },
      { value: "«Бухгалтерский баланс» Документ – 33" },
    ],
  },
  {
    value: "Учет заработной платы",
    chapters: [
      {
        value: "«Электронный табель учета рабочего времени» Документ 15",
        path: "/electronic_timesheet/document15",
      },
    ],
  },
  {
    value: "Бухгалтерский учет",
    chapters: [
      { value: "«Мемориальный ордер №5» Документ 1" },
      { value: "«Счет-фактура» Документ 18" },
      { value: "«Платежное поручение» Документ 9" },
      { value: "«Доверенность» Документ 19" },
      { value: "«Накладная» Документ 20" },
      { value: "«Акт выполненных работ (оказанных услуг)» Документ 21" },
      { value: "«Журнал учета движения товаров» Документ 22" },
      { value: "«Внутренний накладной» Документ 23" },
      { value: "«Раздаточная ведемость» Документ 24" },
      { value: "«Журнал учета износа товаров» Документ 25" },
      { value: "«Акт приёма-передачи товара» Документ – 26" },
      { value: "«Акт списания» Документ 27" },
      {
        value: "«Расходный кассовый ордер» Документ 34",
        path: "/cash_accounting/document34",
      },
      { value: "«Квитанция» Документ 35" },
      { value: "«Кассовая книга» Документ 36" },
      { value: "«Мемориальный ордер №1» Документ 37" },
      { value: "«Приходный кассовый ордер» Документ 38" },
      { value: "«Мемориальный ордер №8» Документ 39" },
      { value: "«Мемориальный ордер №2» Документ 40" },
      { value: "«Мемориальный ордер №6» Документ 41" },
      { value: "«Шакли №1.7» (Варакаи бақиявӣ) Документ 41" },
      { value: "Главная книга – Документ 42" },
      {
        value: "Шакли №1.1- «Қарздории дебитори ва кредиторӣ» – Документ 43",
      },
      {
        value:
          "Шакли №1.2- «Маълумот оид ба амалиётҳои дохилии бахши давлатӣ» - Документ 44",
      },
      {
        value:
          "Шакли №1.3 – «Маълумот оид ба ҳаракати дороиҳои дарозмуддат» - Документ 45",
      },
      {
        value:
          "Шакли №1.4 – «Маълумот оиди гардиши захираҳои моддӣ» - Документ 46",
      },
      {
        value:
          "Шакли №1.5 – «Маълумот оид ба камомад ва дуздиҳои воситаҳои пулӣ ва арзишҳои моддӣ» -Документ 47",
      },
      {
        value:
          "Шакли №1.6 – «Маълумот оид ба фоидаи тақсимнашуда (зарари пӯшиданашуда)» - Документ 48",
      },
      {
        value:
          "Шакли №1.7 – «Варақаи бақиявӣ ба Ҳисобот оид ба ҳолати молиявӣ» - Документ 49",
      },
      { value: "Шакли №1 – « Ҳисобот оид ба  ҳолати молиявӣ» - Документ 50" },
      {
        value: "Шакли №2 – «Ҳисобот оид ба натиҷаҳои молиявӣ» - Документ 51",
      },
      {
        value:
          "Шакли №3 – «Ҳисобот оид ба тағйиротҳои дороиҳои соф – Шакли №3» - Документ 52",
      },
      {
        value:
          "Шакли №4 – «Ҳисобот оид ба ҳаракати воситаҳои пулӣ» - Документ 53",
      },
      {
        value:
          "Шакли №5 – « Ҳисобот доир ба иҷроиши сметаи даромадҳо ва хароҷотҳо» - Документ 54",
      },
    ],
  },
  { value: "Контрагенты", chapters: [{ value: "«Контрагентҳо»" }] },
  {
    value: "Договора/Контракты",
    chapters: [
      { value: "«Шартнома» Документ 17 " },
      { value: "«Феҳристи ӯҳдадориҳои шартномавӣ»" },
    ],
  },
  {
    value: "Кадровый учет",
    chapters: [
      { value: "«Личный листок» Документ 56" },
      { value: "«Свидетельство о трудовом отпуске» Документ 57" },
      { value: "«Не предоставили документы» Документ 58" },
    ],
  },
  {
    value: "Пенсионный учет",
    chapters: [
      { value: "«Руйхати умумии нафақахурон» Документ 59" },
      { value: "«Варақаи электронии пардохт» Документ 60" },
      { value: "«Не предоставили документы» Документ 60" },
    ],
  },
  {
    value:
      "Модуль авторизациии и разграничение доступа к бухгалтерским документам",
    chapters: [{ value: "___", path: "/payroll_accounting/admin" }],
  },
  {
    value: "Цифровое хранилище архивных документов  ",
    chapters: [{ value: "___" }],
  },
];

// kadr
const kadr = [
  {
    value: "Сотрудники",
    active: true,
    chapters: [
      { value: "Общий список сотрудников, находящихся на службе" },
      { value: "Руководство ЦА" },
      { value: "Сотрудники в кадровом резерве" },
      { value: "Ротация сотрудников" },
      { value: "Прием на службу", path: "/kadr/hiring" },
      {
        value: "Добавить текушего сотрудника",
        path: "/kadr/add_current_employee",
      },
    ],
  },
  {
    value: "Приказы",
    chapters: [{ value: "Структурные подразделения" }],
  },
  {
    value: "Маршрутизация писем",
    chapters: [
      { value: "Общий отдел" },
      { value: "ЦА" },
      { value: "Структурные подразделения" },
    ],
  },
  {
    value: "Отчеты",
    chapters: [{ value: "Регламентированные отчеты" }],
  },
  {
    value: "Справочники",
    chapters: [
      { value: "Справочник окладов воинских званий" },
      { value: "Справочник должностных окладов" },
      { value: "Справочник процентных ставок по выслуге лет" },
      { value: "Справочник выплат по сухим пайкам" },
    ],
  },
  {
    value: "Табель УЧР",
    chapters: [{ value: "ЦА" }, { value: "Структурные подразделения" }],
  },
  {
    value: "Календарь отпусков",
    chapters: [
      { value: "ЕОО" },
      { value: "ЕОТ без СЗ" },
      { value: "Отпуск по БР" },
      { value: "Отпуск при СРсО" },
      { value: "Отпуск по УР" },
    ],
  },
];

const payrollAccounting = [
  {
    value: "Зарплата",
    active: true,
    chapters: [
      {
        value: "Расчет зарплаты",
        path: "/payroll_accounting/document2",
      },
      {
        value: "Карточки",
        active: true,
        chapters: [
          { value: "Кадровые данные о сотрудниках" },
          { value: "Список сотрудников" },
        ],
      },
      {
        value: "ТУРВ",
        path: "/electronic_timesheet/document15",
      },
      {
        value: "Преждевременное получение зарплаты ",
        path: "/payroll_accounting/document5",
      },
    ],
  },
  {
    value: "Подготовка бюджета",
    active: false,
    chapters: [
      { value: "Общий список сотрудников, находящихся на службе" },
      { value: "Руководство ЦА" },
      { value: "Сотрудники в кадровом резерве" },
    ],
  },
  {
    value: "Расходы",
    active: false,
    chapters: [
      { value: "Ввод расхода" },
      { value: "Реестр платежных поручений" },
      { value: "Реестр тендерных заявок" },
      { value: "Реестр договоров" },
      { value: "Реестр договорных обязательств" },
      { value: "Реестр ведомостей по ЗП" },
    ],
  },
  {
    value: "Склады",
    active: false,
    chapters: [
      { value: "Учет движения товаров" },
      { value: "Учет износа и амортизации" },
    ],
  },
  {
    value: "Отчеты",
    active: false,
    chapters: [
      { value: "Регламентированные отчеты" },
      { value: "Внутренние отчеты" },
      { value: "Выписка по БЗ" },
      { value: "Анализ по БЗ" },
    ],
  },
  {
    value: "Справочники",
    active: false,
    chapters: [
      { value: "Справочник статей расходов и субсчетов" },
      { value: "Справочник товаров работ и услуг" },
      { value: "Справочник окладов воинских званий" },
      { value: "Справочник должностных окладов" },
      { value: "Справочник выплат по сухим пайкам" },
      { value: "Справочник норм амортизации основных средств" },
      { value: "Справочник норм амортизации основных" },
      { value: "Договора" },
      { value: "Контрагенты (Поставщики)" },
    ],
  },
];

const quentityMonthDays = {
  1: () => 31,
  2: (currYear = null) => {
    let currentYear = currYear ? currYear : getCurrentYear();
    return currentYear % 4 === 0 ||
      (currentYear % 400 === 0 && currentYear % 100 === 0)
      ? 29
      : 28;
  },
  3: () => 31,
  4: () => 30,
  5: () => 31,
  6: () => 30,
  7: () => 31,
  8: () => 31,
  9: () => 30,
  10: () => 31,
  11: () => 30,
  12: () => 31,
};

const defaultLengthOfService = {
  id: 1,
  organization: null,
  jobtitle: {
    label: null,
    value: null,
  },
  rank_id: null,
  start_date: { date: null, dateStr: null },
  end_date: { date: null, dateStr: null },
  is_staff_member: false,
  is_intern: false,
  is_military: false,
  file: null,
  department: {
    label: null,
    value: null,
  },
  order_number: null,
};

const defaultStaff = {
  firstname: "",
  lastname: "",
  middlename: "",
  dob: null,
  details: { passport: {} },
  phone: "",
  tin: "",
  ident_number: "",
  gender: null,
};

const acl = {
  // "accounting-pay_slip": "- - -",
  // "accounting-awards": "- - -",
  // "accounting-time_sheet": "- - -",
  // "accounting-staff": "- - -",
  // "auth-test": "- - -",
  // "time_managment-jobtitle": "- - -",
  // "time_managment-schedule": "- - -",
  // "time_managment-*": "- - -",
  // hr: {
  //   jobtitle: "- - -",
  //   schedule: "ТУРВ",
  //   staff: "Прием на службу",
  //   "*": "*",
  // },

  admin: {
    name: "Администрирование системы",
    module_alias: "admin",
    submodules: [
      {
        submodule_name: "Дастур",
        submodule: "reference_book",
      },
      {
        submodule_name: "Илова кардани корбар",
        submodule: "user_hiring",
      },
    ],
  },
  pension: {
    name: "Пенсионный учет",
    module_alias: "pension",
    submodules: [
      {
        submodule_name: "Кормандони фавтида",
        submodule: "deceased_staff",
      },
      {
        submodule_name: "Кормандон бо гурӯҳи маъюби",
        submodule: "disabled_staff",
      },
      {
        submodule_name: "Баҳисобгирии нафақа",
        submodule: "pension_accounting",
      },
    ],
  },
  hr: {
    name: "Кадровый учет",
    module_alias: "hr",
    submodules: [
      {
        submodule_name: "Таъсиси кормандон",
        submodule: "staff",
      },
      {
        submodule_name: "Ҷадвали таътил",
        submodule: "vacation_schedule",
      },
      {
        submodule_name: "Аз кор озод кардани корманд",
        submodule: "terminate",
      },
      {
        submodule_name: "Фармонҳо",
        submodule: "managment",
      },
      {
        submodule_name: "Тафтишот",
        submodule: "investigation",
      },
      {
        submodule_name: "Ҷазои интизомӣ",
        submodule: "disciplinary_action",
      },
    ],
  },
  time_managment: {
    name: "ЭЛЕКТРОННЫЙ ТАБЕЛЬ УЧЕТА РАБОЧЕГО ВРЕМЕНИ",
    module_alias: "time_managment",
    submodules: [
      {
        submodule_name: "ЭЛЕКТРОННЫЙ ТАБЕЛЬ УЧЕТА РАБОЧЕГО ВРЕМЕНИ",
        submodule: "table",
      },
    ],
  },
  accounting: {
    name: "РАСЧЁТ ЗАРАБОТНОЙ ПЛАТЫ СОТРУДНИКОВ",
    module_alias: "accounting",
    submodules: [
      {
        submodule_name: "РАСЧЁТ ЗАРАБОТНОЙ ПЛАТЫ СОТРУДНИКОВ",
        submodule: "document2",
      },
    ],
  },
  auth: {
    name: "Авторизация",
    module_alias: "auth",
    submodules: [
      {
        submodule_name: "Авторизация",
        submodule: "",
      },
    ],
  },
};

const referenceBookItems = [
  // {
  //   key: "1",
  //   label: "document.admin.acl.acl",
  // },
  {
    key: "2",
    label: "document.admin.roles.roles",
  },
  {
    key: "3",
    label: "document.department",
  },
  {
    key: "4",
    label: "document.marker",
  },
  {
    key: "5",
    label: "document.rank",
  },
  {
    key: "23",
    label: "document.rankbonus",
  },
  // {
  //   key: "6",
  //   label: "Пол",
  // },
  {
    key: "7",
    label: "document.title.retention",
  },
  {
    key: "8",
    label: "document.payment.additionalPaymentType",
  },
  {
    key: "9",
    label: "document.staffStatus",
  },
  {
    key: "10",
    label: "document.dismissalType",
  },
  {
    key: "11",
    label: "document.disciplinaryAction.disciplinaryAction",
  },
  {
    key: "12",
    label: "document.pension.pensionAccounting.pensionType",
  },
  {
    key: "13",
    label: "document.doc_type",
  },
  {
    key: "14",
    label: "document.pension.deceasedGroup",
  },
  {
    key: "15",
    label: "document.pension.disabledGroup",
  },
  {
    key: "16",
    label: "document.admin.staffFinancialAid",
  },
  {
    key: "17",
    label: "document.vacation.vacationType.types",
  },
  {
    key: "18",
    label: "document.admin.tripDailyPay",
  },
  {
    key: "19",
    label: "document.post", //jobtitle
  },
  {
    key: "24",
    label: "document.jobtitleAmount", //jobtitle amount
  },
  {
    key: "20",
    label: "document.admin.countries",
  },
  {
    key: "21",
    label: "document.region",
  },
  {
    key: "22",
    label: "document.pension.pensionAccounting.awards",
  },
];

const publicHolidays =
  [
    {
      type_id: 2,
      start: "01-01",
      end: "01-01",
      title: "С новым годом",
    },
    {
      type_id: 2,
      start: "03-08",
      end: "03-08",
      title: "День матери",
    },
    {
      type_id: 2,
      start: "03-21",
      end: "03-24",
      title: "Навруз",
    },
    {
      type_id: 2,
      start: "06-27",
      end: "06-27",
      title: "День защитников отечества",
    },
    {
      type_id: 2,
      start: "09-09",
      end: "09-09",
      title: "День независимости",
    },
    {
      type_id: 2,
      start: "11-06",
      end: "11-06",
      title: "День Конституции",
    },
  ] && [];

const vacationTypeColor = [
  { color: "rgb(255,0,0)" }, // red
  { color: "rgb(0,0,255)" }, // blue
  { color: "rgb(0,255,0)" }, // green
  { color: "rgb(255,255,0)" }, // yellow
  { color: "rgb(128,0,128)" }, // purple
  { color: "rgb(255,165,0)" }, // orange
  { color: "rgb(255,192,203)" }, // pink
  { color: "rgb(165,42,42)" }, // brown
  { color: "rgb(128,128,128)" }, // gray
  { color: "rgb(0,0,0)" }, // black
  { color: "rgb(255,255,255)" }, // white
  { color: "rgb(0,255,255)" }, // cyan
  { color: "rgb(255,0,255)" }, // magenta
  { color: "rgb(50,205,50)" }, // lime
  { color: "rgb(0,128,128)" }, // teal
  { color: "rgb(0,0,128)" }, // navy
  { color: "rgb(128,0,0)" }, // maroon
  { color: "rgb(128,128,0)" }, // olive
  { color: "rgb(192,192,192)" }, // silver
  { color: "rgb(255,215,0)" }, // gold
  { color: "rgb(205,127,50)" }, // bronze
  { color: "rgb(75,0,130)" }, // indigo
  { color: "rgb(238,130,238)" }, // violet
  { color: "rgb(64,224,208)" }, // turquoise
  { color: "rgb(250,128,114)" }, // salmon
  { color: "rgb(255,127,80)" }, // coral
  { color: "rgb(245,245,220)" }, // beige
  { color: "rgb(255,229,180)" }, // peach
  { color: "rgb(221,160,221)" }, // plum
  { color: "rgb(230,230,250)" }, // lavender
  { color: "rgb(189,252,201)" }, // mint
  { color: "rgb(255,0,255)" }, // cherry
  { color: "rgb(224,176,255)" }, // mauve
  { color: "rgb(255,228,225)" }, // rose
  { color: "rgb(128,0,32)" }, // burgundy
  { color: "rgb(244,164,96)" }, // sand
  { color: "rgb(255,255,240)" }, // ivory
  { color: "rgb(80,200,120)" }, // emerald
  { color: "rgb(15,82,186)" }, // sapphire
  { color: "rgb(255,0,0)" }, // ruby
  { color: "rgb(255,205,0)" }, // topaz
  { color: "rgb(153,102,204)" }, // amethyst
  { color: "rgb(127,255,212)" }, // aquamarine
  { color: "rgb(204,204,255)" }, // periwinkle
  { color: "rgb(0,168,107)" }, // jade
  { color: "rgb(112,66,20)" }, // sepia
  { color: "rgb(54,69,79)" }, // charcoal
  { color: "rgb(251,206,177)" }, // apricot
  { color: "rgb(255,0,255)" }, // fuchsia
  { color: "rgb(0,71,171)" }, // cobalt
  { color: "rgb(255,36,0)" }, // scarlet
  { color: "rgb(0,123,167)" }, // cerulean
  { color: "rgb(229,228,226)" }, // platinum
];

const defaultFormStateFile = {
  files: [{ id: 1, file: null, doc_type: null, doc_number: null }],
};

// Ismoil, [8/23/2024 10:55 AM]
// ай раздели STAFF SCHEDULE!
// UPSERT REQUEST:
// ай запроси UPSERT COMMENTS:
// - Шарҳи такрорӣ!

const queriesMessage = {
  1: { tj: "Сохта шуд!", ru: "" },
  2: { tj: "Тағйир дода шуд!", ru: "" },
  // pension
  3: {
    tj: "Нафақахур вуҷуд надорад!",
    ru: "",
  },
  4: {
    tj: "Нафақа баста шудааст!",
    ru: "",
  },
  // pension
  5: {
    tj: "Нафакахури маъюб дар ҷадвали маъюбон вуҷуд надорад!",
    ru: "",
  },
  6: {
    tj: "Нафакахури марҳум дар ҷадвали марҳум вуҷуд надорад!",
    ru: "",
  },
  // pension
  7: {
    tj: "Навақахур алакай вуҷуд дорад!",
    ru: "",
  },
  // vacation && business trip
  8: {
    tj: "Санаҳои такроршаванда!",
    ru: "",
  },
  // staff schedule
  9: {
    tj: "Моҳ баста аст! Шумо онро таҳрир карда наметавонед!!",
    ru: "",
  },
  10: {
    tj: "Моҳ аллакай баста аст!",
    ru: "",
  },
  11: {
    tj: "Моҳ баста шуд!",
    ru: "",
  },
  12: {
    tj: "Моҳ баста аст! Шумо наметавонед шарҳҳо илова кунед!",
    ru: "",
  },
  13: {
    tj: "Шарҳи такрорӣ!",
    ru: "",
  },
};

export {
  modules,
  payrollAccounting,
  quentityMonthDays,
  kadr,
  defaultLengthOfService,
  defaultStaff,
  acl,
  publicHolidays,
  referenceBookItems,
  defaultFormStateFile,
  vacationTypeColor,
  queriesMessage,
};

// let narr=[]
// modules.map((el) => narr.push(...el.chapters));
// console.log('quentity documents',narr.length)
