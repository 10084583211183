//////////////////////       Swagger AUTH          //////////////////////

import { createAsyncThunk } from "@reduxjs/toolkit";
import { saveToken } from "../../../utils/Token";
import { axiosBaseRequest } from "../../../utils/axiosBaseUrl";
import { axiosRequest } from "../../../utils/axiosURL";

// autorization
export const loginUser = createAsyncThunk("loginUser", async (body) => {
  try {
    const response = await axiosBaseRequest.post("auth/v1/login", body);
    console.log(JSON.stringify(response));
    if (response.status !== 401) {
      saveToken(response.data?.access_token);
      window.location.href = "/";
    } else {
      console.log(response);
    }
    return response;
  } catch (error) {
    console.log("error", error);
  }
});

// reset password
export const resetPassword = createAsyncThunk("resetPassword", async (body) => {
  try {
    const { data } = await axiosRequest.post("auth/v1/password/reset", body);
    return data;
  } catch (error) {
    console.log("error", error);
  }
});
