//////////////////////       Swagger AUTH          //////////////////////

import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosRequest } from "../../../utils/axiosURL";

export const tokenDecode = createAsyncThunk(
  "tokenDecode",
  async (body, { dispatch }) => {
    try {
      const { data } = await axiosRequest.get(`auth/v1/token_decode`);
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const getAcl = createAsyncThunk("getAcl", async () => {
  try {
    const { data } = await axiosRequest.get(`auth/v1/acl`);
    return data;
  } catch (error) {
    console.log("error", error);
  }
});
