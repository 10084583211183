import axios from "axios";
import { destroyToken } from "./Token";
import { axiosBaseUrl } from "./axiosBaseUrl";
import { encruption } from "./encruption";
import { message } from "antd";
import { errorHandler } from "./globalFunction";

const axiosRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    get: {
      // can be common or any other method
      "X-APP-TOKEN": encruption(),
    },
    post: {
      "X-APP-TOKEN": encruption(),
    },
    put: {
      "X-APP-TOKEN": encruption(),
    },
    delete: {
      "X-APP-TOKEN": encruption(),
    },
    patch: {
      "X-APP-TOKEN": encruption(),
    },
  },
  // withCredentials: true
});

axiosRequest.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  // console.log("config", config);
  return config;
});

axiosRequest.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosRequest.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { config, response } = error;
    const originalRequest = config;
    localStorage.setItem("auth_error", error);
    if (response.status > 400 || response.status < 200) {
      errorHandler(response);
      message.error(`${response.status} ${JSON.stringify(response.data)}`, 30);
    }
    if (
      response?.status === 401
      // || error.includes('401')
    ) {
      // make a request to refresh token and update the headers
      try {
        const response = await axiosBaseUrl.get(`auth/v1/refresh_token`);
        if (response.status === 401) {
          destroyToken();
          window.location.href = "/login";
        }
        localStorage.setItem("token", response.data.accessToken);
        originalRequest.headers.Authorization = `Bearer ${response.data.accessToken}`;
        return axios(originalRequest);
      } catch (err) {
        destroyToken();
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

export { axiosRequest };
