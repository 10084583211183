//////////////////////       Swagger Admin          //////////////////////

import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosRequest } from "../../../utils/axiosURL";
import { message } from "antd";

// upsert User
export const upsertUser = createAsyncThunk("upsertUser", async (body) => {
  try {
    const data = await axiosRequest.post("admin/v1/user", body);
    console.log("data", data);
    message.success("Запрос успешен");
    return {
      access_token: data.data.access_token,
      navigate: body.navigate,
    };
  } catch (error) {
    console.log("error", error);
  }
});

// get role
export const getRoleOption = createAsyncThunk("getRoleOption", async () => {
  try {
    const { data } = await axiosRequest.get("admin/v1/common/role");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// get users
export const getUsers = createAsyncThunk("getUsers", async () => {
  try {
    const { data } = await axiosRequest.get("admin/v1/user/");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// get acl
export const getAclAdmin = createAsyncThunk("getAclAdmin", async () => {
  try {
    const { data } = await axiosRequest.get("admin/v1/acl/");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// upsert acl
export const upsertAcl = createAsyncThunk("upsertAcl", async (body) => {
  try {
    const { data } = await axiosRequest.post("admin/v1/acl/", body);
    message.success("Запрос успешен");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// get acl
export const getAclAdminById = createAsyncThunk(
  "getAclAdminById",
  async ({ role_id }) => {
    try {
      const { data } = await axiosRequest.get(`admin/v1/acl/${role_id}`);
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get role
export const upsertRole = createAsyncThunk("upsertRole", async (body) => {
  try {
    const { data } = await axiosRequest.post(
      "admin/v1/common/upsert_role",
      body
    );
    message.success("Запрос успешен");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// upsert department
export const upsertDepartment = createAsyncThunk(
  "upsertDepartment",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/department",
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert marker
export const upsertMarker = createAsyncThunk("upsertMarker", async (body) => {
  try {
    const { data } = await axiosRequest.post("admin/v1/common/marker", body);
    message.success("Запрос успешен");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// upsert rank
export const upsertRank = createAsyncThunk("upsertRank", async (body) => {
  try {
    const { data } = await axiosRequest.post("admin/v1/common/rank", body);
    message.success("Запрос успешен");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// upsert gender
export const upsertGender = createAsyncThunk("upsertGender", async (body) => {
  try {
    const { data } = await axiosRequest.post("admin/v1/common/gender", body);
    message.success("Запрос успешен");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// upsert retention
export const upsertRetention = createAsyncThunk(
  "upsertRetention",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/retentions",
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert additional payment
export const upsertAdditionalPayment = createAsyncThunk(
  "upsertAdditionalPayment",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/additional_payment",
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert staff status
export const upsertStaffStatus = createAsyncThunk(
  "upsertStaffStatus",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/staff_status",
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert dismissal type
export const upsertDismissalType = createAsyncThunk(
  "upsertDismissalType",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/dismissal_type",
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert disciplinary action
export const upsertDisciplinaryAction = createAsyncThunk(
  "upsertDisciplinaryAction",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/disciplinary_action",
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert countries
export const upsertPensionType = createAsyncThunk(
  "upsertPensionType",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/pension_type",
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert document type
export const upsertDocumentType = createAsyncThunk(
  "upsertDocumentType",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/document_type",
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert deceased staff groups
export const upsertDeceasedStaffGroups = createAsyncThunk(
  "upsertDeceasedStaffGroups",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        `admin/v1/common/upsert/deceased_staff_groups`,
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert disabled staff groups
export const upsertDisabledStaffGroups = createAsyncThunk(
  "upsertDisabledStaffGroups",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        `admin/v1/common/upsert/disabled_staff_groups`,
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert staff financial aid
export const upsertStaffFinancialAid = createAsyncThunk(
  "upsertStaffFinancialAid",
  async (body) => {
    try {
      const { data } = await axiosRequest.put(
        `admin/v1/common/upsert/staff_financial_aid`,
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert types
export const upsertVacationOption = createAsyncThunk(
  "upsertVacationOption",
  async (body, { dispatch }) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/upsert/vacation_group",
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert trip daily pay
export const upsertTripDailyPayOption = createAsyncThunk(
  "upsertTripDailyPayOption",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/upsert/trip_daily_pay",
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert jobtitle
export const upsertJobtitle = createAsyncThunk(
  "upsertJobtitle",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/jobtitle",
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert countries
export const upsertCountries = createAsyncThunk(
  "upsertCountries",
  async (body) => {
    try {
      const { data } = await axiosRequest.put(
        "admin/v1/common/update/countries",
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert region
export const upsertRegion = createAsyncThunk("upsertRegion", async (body) => {
  try {
    const { data } = await axiosRequest.post(
      "admin/v1/common/upsert/region",
      body
    );
    message.success("Запрос успешен");
    return data;
  } catch (error) {
    console.log("error", error);
  }
});

// upsert pension awards
export const upsertPensionAwards = createAsyncThunk(
  "upsertPensionAwards",
  async (body) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/upsert/pension_awards",
        body
      );
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert rankbonus
export const upsertRankbonus = createAsyncThunk(
  "upsertRankbonus",
  async (body, { dispatch }) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/rankbonus",
        body
      );
      message.success("Запрос успешен");
      dispatch(getRankbonus({}));
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get rankbonus
export const getRankbonus = createAsyncThunk(
  "getRankbonus",
  async ({ rank_id }) => {
    try {
      const { data } = await axiosRequest.get(
        `admin/v1/common/rankbonus${rank_id ? `?rank_id=${rank_id}` : ""}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// upsert jobtitle amount
export const upsertJobtitleAmout = createAsyncThunk(
  "upsertJobtitleAmout",
  async (body, { dispatch }) => {
    try {
      const { data } = await axiosRequest.post(
        "admin/v1/common/jobtitle/amount",
        body
      );
      message.success("Запрос успешен");
      dispatch(getJobtitleAmount({}));
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get jobtitle amount
export const getJobtitleAmount = createAsyncThunk(
  "getJobtitleAmount",
  async ({ jobtitle_id }) => {
    try {
      const { data } = await axiosRequest.get(
        `admin/v1/common/jobtitle/amount${
          jobtitle_id ? `?jobtitle_id=${jobtitle_id}` : ""
        }`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);
