import { getAcl, tokenDecode } from "../MvdFeuLocalStorageState/AuthQueries";
// import {
//   getTransactionSalaryIntermediate,
//   postTransactionSalaryIntermediateAccept,
//   postTransactionSalaryIntermediateDecline,
// } from "../MvdFeuLocalStorageState/PaymentQueries";

const AuthExtraReducers = (builder) => {
  // token decode
  builder.addCase(tokenDecode.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(tokenDecode.fulfilled, (state, action) => {
    state.loading = false;
    state.user = action.payload;
    // console.log("user", action.payload);
  });
  builder.addCase(tokenDecode.rejected, (state, action) => {
    state.loading = false;
  });

  // get acl
  builder.addCase(getAcl.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getAcl.fulfilled, (state, action) => {
    state.loading = false;
    state.userAcl = action.payload;
  });
  builder.addCase(getAcl.rejected, (state, action) => {
    state.loading = false;
  });
};

// const PaymentExtraReducers = (builder) => {
//   // get transaction salary intermediate
//   builder.addCase(getTransactionSalaryIntermediate.pending, (state, action) => {
//     state.loading = true;
//   });
//   builder.addCase(
//     getTransactionSalaryIntermediate.fulfilled,
//     (state, action) => {
//       state.loading = false;
//       state.taransactionSalaryIntermediate = action.payload;
//     }
//   );
//   builder.addCase(
//     getTransactionSalaryIntermediate.rejected,
//     (state, action) => {
//       state.loading = false;
//     }
//   );

//   // post transaction salary intermediate accept
//   builder.addCase(
//     postTransactionSalaryIntermediateAccept.pending,
//     (state, action) => {
//       state.loading = true;
//     }
//   );
//   builder.addCase(
//     postTransactionSalaryIntermediateAccept.fulfilled,
//     (state, action) => {
//       state.loading = false;
//       state.taransactionSalaryIntermediateDoc = action.payload;
//     }
//   );
//   builder.addCase(
//     postTransactionSalaryIntermediateAccept.rejected,
//     (state, action) => {
//       state.loading = false;
//     }
//   );

//   // post transaction salary intermediate decline
//   builder.addCase(
//     postTransactionSalaryIntermediateDecline.pending,
//     (state, action) => {
//       state.loading = true;
//     }
//   );
//   builder.addCase(
//     postTransactionSalaryIntermediateDecline.fulfilled,
//     (state, action) => {
//       state.loading = false;
//       state.taransactionSalaryIntermediate = [];
//     }
//   );
//   builder.addCase(
//     postTransactionSalaryIntermediateDecline.rejected,
//     (state, action) => {
//       state.loading = false;
//     }
//   );
// };

export function MvdFeuLocalStorageStateExtraReducers(builder) {
  ////////    Swagger AUTH    ////////
  AuthExtraReducers(builder);

  // ////////    Swagger PAYMENT    ////////
  // PaymentExtraReducers(builder);
}
