import axios from "axios";
import { destroyToken } from "./Token";
import { encruption } from "./encruption";

const axiosBaseUrl = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    get: {
      // can be common or any other method
      "X-APP-TOKEN": encruption(),
    },
    post: {
      "X-APP-TOKEN": encruption(),
    },
    edit: {
      "X-APP-TOKEN": encruption(),
    },
    delete: {
      "X-APP-TOKEN": encruption(),
    },
    patch: {
      "X-APP-TOKEN": encruption(),
    },
  },
});

const axiosBaseRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    get: {
      // can be common or any other method
      "X-APP-TOKEN": encruption(),
    },
    post: {
      // can be common or any other method
      "X-APP-TOKEN": encruption(),
    },
    edit: {
      // can be common or any other method
      "X-APP-TOKEN": encruption(),
    },
    delete: {
      // can be common or any other method
      "X-APP-TOKEN": encruption(),
    },
  },
});

axiosBaseRequest.interceptors.request.use((config) => {
  // config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
});

axiosBaseRequest.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("token");
    if (accessToken) {
      // config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosBaseRequest.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { config, response } = error;
    const originalRequest = config;

    if (
      response?.status === 401
      // || error.includes('401')
    ) {
      const refreshToken = localStorage.getItem("refresh_token");
      if (!refreshToken) {
        destroyToken();
        return Promise.reject(error);
      }
      // make a request to refresh token and update the headers
      try {
        const response = await axios.get(`auth/v1/refresh_token`, {
          headers: {
            get: {
              "X-APP-TOKEN": encruption(),
            },
          },
        });
        localStorage.setItem(
          "refresh_error",
          `refresh token error: ${JSON.stringify(response)}`
        );
        // alert(JSON.stringify(response))
        if (response.status === 401) {
          // alert(JSON.stringify(response))
          console.log("/login", response);
          destroyToken();
          window.location.href = "/login";
        }
        localStorage.setItem("token", response.data.accessToken);
        originalRequest.headers.Authorization = `Bearer ${response.data.accessToken}`;
        return axios(originalRequest);
      } catch (err) {
        destroyToken();
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

export { axiosBaseUrl, axiosBaseRequest };
