import { Suspense, lazy } from "react";
import Loader from "../components/loader/Loader";
import { Outlet, Route, Routes } from "react-router-dom";

const Layout = lazy(() => import("../components/layout/Layout"));

// login
const Login = lazy(() => import("../pages/login/Login"));
const LoginEDS = lazy(() => import("../pages/login/LoginEDS"));

// admin
const ACL = lazy(() => import("../pages/admin/ACL"));
const UserHiring = lazy(() => import("../pages/admin/UserHiring"));
const ReferenceBook = lazy(() => import("../pages/admin/ReferenceBook"));

// payroll_accounting
const Document1 = lazy(() => import("../pages/payroll_accounting/Document1"));
const Document2 = lazy(() => import("../pages/payroll_accounting/Document2"));
const Document3 = lazy(() => import("../pages/payroll_accounting/Document3"));
const Document4 = lazy(() => import("../pages/payroll_accounting/Document4"));
const Document5 = lazy(() => import("../pages/payroll_accounting/Document5"));
const Document6 = lazy(() => import("../pages/payroll_accounting/Document6"));
const Document7 = lazy(() => import("../pages/payroll_accounting/Document7"));
const AdditionalPayment = lazy(() =>
  import("../pages/payroll_accounting/AdditionalPayment")
);
const Retention = lazy(() => import("../pages/payroll_accounting/Retention"));

// cash_accounting
const Document34 = lazy(() => import("../pages/cash_accounting/Document34"));

// kadr
const Hiring = lazy(() => import("../pages/kadr/Hiring"));
const VacationSchedule = lazy(() => import("../pages/kadr/VacationSchedule"));
const Terminate = lazy(() => import("../pages/kadr/Terminate"));
const Managment = lazy(() => import("../pages/kadr/Managment"));
const Investigation = lazy(() => import("../pages/kadr/Investigation"));
const DisciplinaryAction = lazy(() =>
  import("../pages/kadr/DisciplinaryAction")
);
const BusinessTrip = lazy(() => import("../pages/kadr/BusinessTrip"));
const Document15 = lazy(() => import("../pages/kadr/Document15"));

// Pancion accouting
const DeceasedStaff = lazy(() =>
  import("../pages/pension_accounting/DeceasedStaff")
);
const DisabledStaff = lazy(() =>
  import("../pages/pension_accounting/DisabledStaff")
);
const PensionAccounting = lazy(() =>
  import("../pages/pension_accounting/PensionAccounting")
);

// accounting
const ReciptSalary = lazy(() => import("../pages/accounting/ReciptSalary"));
const MoneyCertificate = lazy(() =>
  import("../pages/accounting/MoneyCertificate")
);

// other
const Page404 = lazy(() => import("../pages/other/Page404"));
const Page403 = lazy(() => import("../pages/other/Page403"));

// settings
const ChangePassword = lazy(() => import("../pages/Settings/ChangePassword"));
const UserData = lazy(() => import("../pages/Settings/UserData"));

const suspense = (htmlElem) => (
  <Suspense fallback={<Loader />}>{htmlElem}</Suspense>
);

const RoutesCom = () => {
  return (
    <Routes>
      <Route
        path="/login"
        element={suspense(
          <div>
            <Outlet />
          </div>
        )}
      >
        <Route index element={suspense(<Login />)} />
        <Route path="/login/eds" element={suspense(<LoginEDS />)} />
      </Route>
      <Route path="/" element={suspense(<Layout />)}>
        {/* <Route
        index
        element={suspense(
          <div className="w-full h-full p-[20px_10px_10px_10px]">
            <div className="w-full h-full m-auto bg-rgba(167,202,222,1)] rounded-[15px]">
              This is not used page
            </div>
          </div>
        )}
      /> */}
        {/* admin */}
        <Route path="/admin/acl" element={suspense(<ACL />)} />
        <Route path="/admin/common" element={suspense(<ReferenceBook />)} />
        <Route path="/admin/user" element={suspense(<UserHiring />)} />

        {/* payroll_accounting */}
        <Route path="/payment/document1" element={suspense(<Document1 />)} />
        <Route path="/payment/salary" element={suspense(<Document2 />)} />
        <Route path="/payment/document3" element={suspense(<Document3 />)} />
        <Route path="/payment/document4" element={suspense(<Document4 />)} />
        <Route path="/payment/document5" element={suspense(<Document5 />)} />
        <Route path="/payment/document6" element={suspense(<Document6 />)} />
        <Route path="/payment/document7" element={suspense(<Document7 />)} />
        <Route
          path="/payment/additional_payment"
          element={suspense(<AdditionalPayment />)}
        />
        <Route path="/payment/retention" element={suspense(<Retention />)} />

        {/* cash_accounting */}
        <Route
          path="/cash_accounting/document34"
          element={suspense(<Document34 />)}
        />

        {/* kadr */}
        <Route path="/hr/staff" element={suspense(<Hiring />)} />
        <Route path="/hr/vacation" element={suspense(<VacationSchedule />)} />
        <Route path="/hr/terminate" element={suspense(<Terminate />)} />
        <Route path="/hr/managment" element={suspense(<Managment />)} />
        <Route path="/hr/investigation" element={suspense(<Investigation />)} />
        <Route
          path="/hr/disciplinary_action"
          element={suspense(<DisciplinaryAction />)}
        />
        <Route path="/hr/business_trip" element={suspense(<BusinessTrip />)} />
        <Route path="/hr/schedule" element={suspense(<Document15 />)} />

        {/* pencion accouting */}
        <Route
          path="/pension/deceased_staff"
          element={suspense(<DeceasedStaff />)}
        />
        <Route
          path="/pension/disabled_staff"
          element={suspense(<DisabledStaff />)}
        />
        <Route
          path="/pension/pension"
          element={suspense(<PensionAccounting />)}
        />

        {/* accounting */}
        <Route
          path="/accounting/recipt_salary"
          element={suspense(<ReciptSalary />)}
        />
        <Route
          path="/accounting/money_sertificate"
          element={suspense(<MoneyCertificate />)}
        />

        {/* settings */}
        <Route
          path="/settings/change_password"
          element={suspense(<ChangePassword />)}
        />
        <Route path="/settings/user_data" element={suspense(<UserData />)} />
      </Route>
      <Route path="*" element={suspense(<Page404 />)} />
      <Route path="/access-denied" element={suspense(<Page403 />)} />
    </Routes>
  );
};

export default RoutesCom;
