import { loginUser, resetPassword } from "../globalStateQueries/AuthQueries";
import {
  getAdditionalPaymentsOption,
  getCalendarYear,
  getCountries,
  getDeceasedStaffGroups,
  getDepartmentOption,
  getDisabledStaffGroups,
  getDisciplinaryAction,
  getDismissalTypes,
  getDocumentType,
  getGenderOption,
  getJobtitleOption,
  getMarkerOption,
  getModules,
  getMonthDate,
  getPensionAwards,
  getPensionTypes,
  getRankOption,
  getRegion,
  getRetentionsOption,
  getStaffFinancialAid,
  getStaffStatus,
  getTripDailyPayOptions,
  getVacationOption,
} from "../globalStateQueries/CommonQueries";
import {
  changeScheduleAndAddComment,
  changeStaffJobposition,
  createOrUpdateJobposition,
  createOrUpdateStaff,
  createOrUpdateStaffChild,
  createOrUpdateStaffVacationSchedule,
  createOrUpdateuDeceasedStaff,
  createOrUpdateuDisabledStaff,
  createStaffDisciplinaryAction,
  createStaffInvestigation,
  createStaffManagement,
  createStaffTerminate,
  finishSchedule,
  getDeceasedStaffsOrStaffById,
  getDisabledStaffsOrStaffById,
  getSchedule,
  getStaffJobposition,
  getStaffJobpositions,
  getStaffSalary,
  getStaffsByDepartmentId,
  getStaffsOrStaffById,
  getStaffsOrStaffByIdDisciplinaryAction,
  getStaffsOrStaffByIdInvestigation,
  getStaffsOrStaffByIdManagement,
  getStaffsTerminate,
  getStaffsVacationSchedule,
  getStaffChildByStaffId,
  staffReinstatement,
  updateSchedule,
  updateStaffManagment,
  uploadJobpositionFile,
  upsertBusinessTrip,
  getDepartmentBusinessTrip,
  getStaffBusinessTrip,
  getStaffsInvestigationByDepartmentId,
  getStaffsManagementByDepartmentId,
} from "../globalStateQueries/HrQueries";
import {
  getPensionAccountingById,
  getPensioners,
  upsertPensionAccounting,
} from "../globalStateQueries/PesionQueries";
import { setCurrentYear } from "../../../utils/globalFunction";
import {
  getAclAdmin,
  getAclAdminById,
  getJobtitleAmount,
  getRankbonus,
  getRoleOption,
  getUsers,
  upsertAcl,
  upsertAdditionalPayment,
  upsertCountries,
  upsertDeceasedStaffGroups,
  upsertDepartment,
  upsertDisabledStaffGroups,
  upsertDisciplinaryAction,
  upsertDismissalType,
  upsertDocumentType,
  upsertGender,
  upsertJobtitle,
  upsertJobtitleAmout,
  upsertMarker,
  upsertPensionAwards,
  upsertPensionType,
  upsertRank,
  upsertRankbonus,
  upsertRegion,
  upsertRetention,
  upsertRole,
  upsertStaffFinancialAid,
  upsertStaffStatus,
  upsertTripDailyPayOption,
  upsertUser,
  upsertVacationOption,
} from "../globalStateQueries/AdminQueries";
import { getDocById, getFileById } from "../globalStateQueries/StorageQueries";
import {
  getTransactionSalaryIntermediate,
  postTransactionSalaryIntermediateAccept,
  postTransactionSalaryIntermediateDecline,
  createStaffRetention,
  createStaffAdditionalPayment,
  getAdditionalPayments,
  getRetentions,
  getTransactionSalaryIntermediateBankData,
} from "../globalStateQueries/PaymentQueries";

const AuthExtraReducers = (builder) => {
  // login user ==================
  builder.addCase(loginUser.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(loginUser.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(loginUser.rejected, (state, action) => {
    state.loading = false;
    console.log(action.payload);
  });

  // reset password
  builder.addCase(resetPassword.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(resetPassword.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(resetPassword.rejected, (state, action) => {
    state.loading = false;
    console.log(action.payload);
  });
};

const CommonExtraReducers = (builder) => {
  // get modules
  builder.addCase(getModules.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getModules.fulfilled, (state, action) => {
    state.loading = false;
    state.modules = action.payload;
  });
  builder.addCase(getModules.rejected, (state, action) => {
    state.loading = false;
  });

  // get department option
  builder.addCase(getDepartmentOption.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getDepartmentOption.fulfilled, (state, action) => {
    state.loading = false;
    state.departments = action.payload;
    state.departmentOption = action.payload;
  });
  builder.addCase(getDepartmentOption.rejected, (state, action) => {
    state.loading = false;
  });

  // get rank option
  builder.addCase(getRankOption.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getRankOption.fulfilled, (state, action) => {
    state.loading = false;
    state.rankOption = action.payload;
  });
  builder.addCase(getRankOption.rejected, (state, action) => {
    state.loading = false;
  });

  // get rank option
  builder.addCase(getGenderOption.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getGenderOption.fulfilled, (state, action) => {
    state.loading = false;
    state.genderOption = action.payload;
  });
  builder.addCase(getGenderOption.rejected, (state, action) => {
    state.loading = false;
  });

  // get marker option
  builder.addCase(getMarkerOption.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getMarkerOption.fulfilled, (state, action) => {
    state.loading = false;
    state.markerOption = action.payload;
  });
  builder.addCase(getMarkerOption.rejected, (state, action) => {
    state.loading = false;
  });

  // get month date
  builder.addCase(getMonthDate.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getMonthDate.fulfilled, (state, action) => {
    state.loading = false;
    state.monthDate = action.payload;
    if (action.payload?.current_date) {
      setCurrentYear(action.payload?.current_date?.split("-")[0]);
    } else {
      setCurrentYear(action.payload?.first_date?.split("-")[0]);
    }
  });
  builder.addCase(getMonthDate.rejected, (state, action) => {
    state.loading = false;
  });

  // get retentions option
  builder.addCase(getRetentionsOption.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getRetentionsOption.fulfilled, (state, action) => {
    state.loading = false;
    state.retentionsOption = action.payload;
  });
  builder.addCase(getRetentionsOption.rejected, (state, action) => {
    state.loading = false;
  });

  // get additional payment
  builder.addCase(getAdditionalPaymentsOption.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getAdditionalPaymentsOption.fulfilled, (state, action) => {
    state.loading = false;
    state.additionalPaymentOption = action.payload;
  });
  builder.addCase(getAdditionalPaymentsOption.rejected, (state, action) => {
    state.loading = false;
  });

  // get staff status
  builder.addCase(getStaffStatus.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getStaffStatus.fulfilled, (state, action) => {
    state.loading = false;
    state.staffStatus = action.payload;
  });
  builder.addCase(getStaffStatus.rejected, (state, action) => {
    state.loading = false;
  });

  // get dismissal types
  builder.addCase(getDismissalTypes.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getDismissalTypes.fulfilled, (state, action) => {
    state.loading = false;
    state.dismissalTypes = action.payload;
  });
  builder.addCase(getDismissalTypes.rejected, (state, action) => {
    state.loading = false;
  });

  // get calendar year
  builder.addCase(getCalendarYear.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getCalendarYear.fulfilled, (state, action) => {
    state.loading = false;
    state.calendarYear = action.payload;
  });
  builder.addCase(getCalendarYear.rejected, (state, action) => {
    state.loading = false;
  });

  // get disciplinary action
  builder.addCase(getDisciplinaryAction.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getDisciplinaryAction.fulfilled, (state, action) => {
    state.loading = false;
    state.disciplinaryActionOption = action.payload;
  });
  builder.addCase(getDisciplinaryAction.rejected, (state, action) => {
    state.loading = false;
  });

  // get pension types
  builder.addCase(getPensionTypes.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getPensionTypes.fulfilled, (state, action) => {
    state.loading = false;
    state.pensionTypes = action.payload;
  });
  builder.addCase(getPensionTypes.rejected, (state, action) => {
    state.loading = false;
  });

  // get document type
  builder.addCase(getDocumentType.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getDocumentType.fulfilled, (state, action) => {
    state.loading = false;
    state.documentTypes = action.payload;
  });
  builder.addCase(getDocumentType.rejected, (state, action) => {
    state.loading = false;
  });

  // get deceased staff groups
  builder.addCase(getDeceasedStaffGroups.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getDeceasedStaffGroups.fulfilled, (state, action) => {
    state.loading = false;
    state.deceasedStaffGroups = action.payload;
  });
  builder.addCase(getDeceasedStaffGroups.rejected, (state, action) => {
    state.loading = false;
  });

  // get disabled staff groups
  builder.addCase(getDisabledStaffGroups.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getDisabledStaffGroups.fulfilled, (state, action) => {
    state.loading = false;
    state.disabledStaffGroups = action.payload;
  });
  builder.addCase(getDisabledStaffGroups.rejected, (state, action) => {
    state.loading = false;
  });

  // get staff financial aid
  builder.addCase(getStaffFinancialAid.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getStaffFinancialAid.fulfilled, (state, action) => {
    state.loading = false;
    state.staffFinancialAid = action.payload;
  });
  builder.addCase(getStaffFinancialAid.rejected, (state, action) => {
    state.loading = false;
  });

  // get vacation types
  builder.addCase(getVacationOption.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getVacationOption.fulfilled, (state, action) => {
    state.loading = false;
    state.vacationOption = action.payload;
  });
  builder.addCase(getVacationOption.rejected, (state, action) => {
    state.loading = false;
  });

  // upsert business trip
  builder.addCase(upsertBusinessTrip.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(upsertBusinessTrip.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(upsertBusinessTrip.rejected, (state, action) => {
    state.loading = false;
  });

  // get department business trip
  builder.addCase(getDepartmentBusinessTrip.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getDepartmentBusinessTrip.fulfilled, (state, action) => {
    state.loading = false;
    state.departmentBusinessTrip = action.payload;
  });
  builder.addCase(getDepartmentBusinessTrip.rejected, (state, action) => {
    state.loading = false;
  });

  // get staff business trip
  builder.addCase(getStaffBusinessTrip.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getStaffBusinessTrip.fulfilled, (state, action) => {
    state.loading = false;
    state.staffBusinessTrip = action.payload;
  });
  builder.addCase(getStaffBusinessTrip.rejected, (state, action) => {
    state.loading = false;
  });

  // get trip daily pay
  builder.addCase(getTripDailyPayOptions.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getTripDailyPayOptions.fulfilled, (state, action) => {
    state.loading = false;
    state.tripDailyPayOptions = action.payload;
  });
  builder.addCase(getTripDailyPayOptions.rejected, (state, action) => {
    state.loading = false;
  });

  // get jobtitle option
  builder.addCase(getJobtitleOption.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getJobtitleOption.fulfilled, (state, action) => {
    state.loading = false;
    state.jobtitleOption = action.payload;
  });
  builder.addCase(getJobtitleOption.rejected, (state, action) => {
    state.loading = false;
  });

  // get countries
  builder.addCase(getCountries.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getCountries.fulfilled, (state, action) => {
    state.loading = false;
    state.countries = action.payload;
  });
  builder.addCase(getCountries.rejected, (state, action) => {
    state.loading = false;
  });

  // get regions
  builder.addCase(getRegion.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getRegion.fulfilled, (state, action) => {
    state.loading = false;
    state.region = action.payload;
  });
  builder.addCase(getRegion.rejected, (state, action) => {
    state.loading = false;
  });

  // get pension awards
  builder.addCase(getPensionAwards.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getPensionAwards.fulfilled, (state, action) => {
    state.loading = false;
    state.pensionAwards = action.payload;
  });
  builder.addCase(getPensionAwards.rejected, (state, action) => {
    state.loading = false;
  });
};

const HrExtraReducers = (builder) => {
  // create or update staff
  builder.addCase(createOrUpdateStaff.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(createOrUpdateStaff.fulfilled, (state, action) => {
    state.loading = false;
    const id = action.payload?.id;
    if (id) {
      state.staff = action.payload;
    }
  });
  builder.addCase(createOrUpdateStaff.rejected, (state, action) => {
    state.loading = false;
  });

  // get staffs or staff by id
  builder.addCase(getStaffsOrStaffById.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getStaffsOrStaffById.fulfilled, (state, action) => {
    state.loading = false;
    if (Array.isArray(action.payload)) {
      // console.log(dayjs(action.payload.dob))
      state.staffs = action.payload;
      // state.staffs = {...action.payload,dob:dayjs(action.payload.dob)};
    } else {
      state.staff = action.payload;
    }
    console.log("staff or staffs", action.payload);
    // state.jobtitleOption = action.payload;
  });
  builder.addCase(getStaffsOrStaffById.rejected, (state, action) => {
    state.loading = false;
  });

  // get staffs by department id
  builder.addCase(getStaffsByDepartmentId.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getStaffsByDepartmentId.fulfilled, (state, action) => {
    state.loading = false;
    console.log(action.payload, state.staffs?.length);
    // if (action.payload?.savePrev) {
    //   if (state.staffs?.length) {
    //     state.staffs = [...state.staffs, ...action.payload?.data];
    //   } else {
    //     state.staffs = action.payload?.data;
    //   }
    // } else {
    //   state.staffs = action.payload?.data;
    // }
    // if (action.payload?.setDepartmentId) {
    //   state.departmentId = action.payload?.department_id || 0;
    // }
    state.staffs = action.payload?.data;
    state.departmentId = action.payload?.department_id || 0;
  });
  builder.addCase(getStaffsByDepartmentId.rejected, (state, action) => {
    state.loading = false;
  });

  // creat or update jobposition
  builder.addCase(createOrUpdateJobposition.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(createOrUpdateJobposition.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(createOrUpdateJobposition.rejected, (state, action) => {
    state.loading = false;
  });

  // change staff jobposition
  builder.addCase(changeStaffJobposition.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(changeStaffJobposition.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(changeStaffJobposition.rejected, (state, action) => {
    state.loading = false;
  });

  // upload jobposition file
  builder.addCase(uploadJobpositionFile.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(uploadJobpositionFile.fulfilled, (state, action) => {
    state.loading = false;
    // state.jobtitleOption = action.payload;
  });
  builder.addCase(uploadJobpositionFile.rejected, (state, action) => {
    state.loading = false;
  });

  // get jobposition
  builder.addCase(getStaffJobposition.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getStaffJobposition.fulfilled, (state, action) => {
    state.loading = false;
    if (action.payload) {
      state.jobposition = action.payload;
    }
  });
  builder.addCase(getStaffJobposition.rejected, (state, action) => {
    state.loading = false;
  });

  // get jobpositions
  builder.addCase(getStaffJobpositions.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getStaffJobpositions.fulfilled, (state, action) => {
    state.loading = false;
    if (action.payload) {
      state.jobpositions = action.payload;
      state.jobposition = action.payload[0];
      state.lengthOfService = action.payload;
    }
  });
  builder.addCase(getStaffJobpositions.rejected, (state, action) => {
    state.loading = false;
  });

  // get staff salary
  builder.addCase(getStaffSalary.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getStaffSalary.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(getStaffSalary.rejected, (state, action) => {
    state.loading = false;
  });

  // create staff managment
  builder.addCase(createStaffManagement.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(createStaffManagement.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(createStaffManagement.rejected, (state, action) => {
    state.loading = false;
  });

  // get staff managment
  builder.addCase(getStaffsOrStaffByIdManagement.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getStaffsOrStaffByIdManagement.fulfilled, (state, action) => {
    state.loading = false;
    state.staffsManagment = action.payload;
  });
  builder.addCase(getStaffsOrStaffByIdManagement.rejected, (state, action) => {
    state.loading = false;
  });

  // get staff managment by department id
  builder.addCase(
    getStaffsManagementByDepartmentId.pending,
    (state, action) => {
      state.loading = true;
    }
  );
  builder.addCase(
    getStaffsManagementByDepartmentId.fulfilled,
    (state, action) => {
      state.loading = false;
      state.staffsManagment = action.payload;
    }
  );
  builder.addCase(
    getStaffsManagementByDepartmentId.rejected,
    (state, action) => {
      state.loading = false;
    }
  );

  // update staff managment
  builder.addCase(updateStaffManagment.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(updateStaffManagment.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(updateStaffManagment.rejected, (state, action) => {
    state.loading = false;
  });

  // create staff terminate
  builder.addCase(createStaffTerminate.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(createStaffTerminate.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(createStaffTerminate.rejected, (state, action) => {
    state.loading = false;
  });

  // get staffs terminate
  builder.addCase(getStaffsTerminate.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getStaffsTerminate.fulfilled, (state, action) => {
    state.loading = false;
    state.staffsTerminate = action.payload;
  });
  builder.addCase(getStaffsTerminate.rejected, (state, action) => {
    state.loading = false;
  });

  // create staff disciplinary action
  builder.addCase(createStaffDisciplinaryAction.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(createStaffDisciplinaryAction.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(createStaffDisciplinaryAction.rejected, (state, action) => {
    state.loading = false;
  });

  // get staffs or staff by id disciplinary action
  builder.addCase(
    getStaffsOrStaffByIdDisciplinaryAction.pending,
    (state, action) => {
      state.loading = true;
    }
  );
  builder.addCase(
    getStaffsOrStaffByIdDisciplinaryAction.fulfilled,
    (state, action) => {
      state.loading = false;
      if (Array.isArray(action.payload)) {
        state.staffsDisciplinaryAction = action.payload;
      } else {
        state.staffDisciplinaryAction = action.payload;
      }
    }
  );
  builder.addCase(
    getStaffsOrStaffByIdDisciplinaryAction.rejected,
    (state, action) => {
      state.loading = false;
    }
  );

  // create staff investigation
  builder.addCase(createStaffInvestigation.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(createStaffInvestigation.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(createStaffInvestigation.rejected, (state, action) => {
    state.loading = false;
  });

  // get staffs or staff by id investigation
  builder.addCase(
    getStaffsOrStaffByIdInvestigation.pending,
    (state, action) => {
      state.loading = true;
    }
  );
  builder.addCase(
    getStaffsOrStaffByIdInvestigation.fulfilled,
    (state, action) => {
      state.loading = false;
      if (Array.isArray(action.payload) || action.payload === null) {
        state.staffsInvestigation = action.payload;
      } else {
        state.staffInvestigation = action.payload;
      }
    }
  );
  builder.addCase(
    getStaffsOrStaffByIdInvestigation.rejected,
    (state, action) => {
      state.loading = false;
    }
  );

  // get staffs investigation by department id
  builder.addCase(
    getStaffsInvestigationByDepartmentId.pending,
    (state, action) => {
      state.loading = true;
    }
  );
  builder.addCase(
    getStaffsInvestigationByDepartmentId.fulfilled,
    (state, action) => {
      state.loading = false;
      state.staffsInvestigation = action.payload;
    }
  );
  builder.addCase(
    getStaffsInvestigationByDepartmentId.rejected,
    (state, action) => {
      state.loading = false;
    }
  );

  // staff reinstatement
  builder.addCase(staffReinstatement.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(staffReinstatement.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(staffReinstatement.rejected, (state, action) => {
    state.loading = false;
  });

  // Document 15 ###################

  // get schedule
  builder.addCase(getSchedule.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getSchedule.fulfilled, (state, action) => {
    state.loading = false;
    // const scheduleData = action.payload
    //   ? { ...action.payload }
    //   : action.payload;
    state.scheduleDate = action.payload;
  });
  builder.addCase(getSchedule.rejected, (state, action) => {
    state.loading = false;
  });

  // updatge shedule
  builder.addCase(updateSchedule.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(updateSchedule.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(updateSchedule.rejected, (state, action) => {
    state.loading = false;
  });

  // close month for shedule
  builder.addCase(finishSchedule.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(finishSchedule.fulfilled, (state, action) => {
    state.loading = false;
    // state.scheduleDate = action.payload;
  });
  builder.addCase(finishSchedule.rejected, (state, action) => {
    state.loading = false;
  });

  // change shedule and addComment
  builder.addCase(changeScheduleAndAddComment.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(changeScheduleAndAddComment.fulfilled, (state, action) => {
    state.loading = false;
    // state.scheduleDate = action.payload;
    state.activeScheduleChange = { active: false, comment: "" };
  });
  builder.addCase(changeScheduleAndAddComment.rejected, (state, action) => {
    state.loading = false;
  });

  // change shedule and addComment
  builder.addCase(
    createOrUpdateStaffVacationSchedule.pending,
    (state, action) => {
      state.loading = true;
    }
  );
  builder.addCase(
    createOrUpdateStaffVacationSchedule.fulfilled,
    (state, action) => {
      state.loading = false;
    }
  );
  builder.addCase(
    createOrUpdateStaffVacationSchedule.rejected,
    (state, action) => {
      state.loading = false;
    }
  );

  // get staffs vacation schedule
  builder.addCase(getStaffsVacationSchedule.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getStaffsVacationSchedule.fulfilled, (state, action) => {
    state.loading = false;
    state.staffsVacations = action.payload;
  });
  builder.addCase(getStaffsVacationSchedule.rejected, (state, action) => {
    state.loading = false;
  });

  // create or update deceased staff
  builder.addCase(createOrUpdateuDeceasedStaff.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(createOrUpdateuDeceasedStaff.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(createOrUpdateuDeceasedStaff.rejected, (state, action) => {
    state.loading = false;
  });

  // get deceased staffs or staff by id
  builder.addCase(getDeceasedStaffsOrStaffById.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getDeceasedStaffsOrStaffById.fulfilled, (state, action) => {
    state.loading = false;
    state.deceasedStaffs = action.payload;
  });
  builder.addCase(getDeceasedStaffsOrStaffById.rejected, (state, action) => {
    state.loading = false;
  });

  // create or update disabled staff
  builder.addCase(createOrUpdateuDisabledStaff.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(createOrUpdateuDisabledStaff.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(createOrUpdateuDisabledStaff.rejected, (state, action) => {
    state.loading = false;
  });

  // get disabled staff by id
  builder.addCase(getDisabledStaffsOrStaffById.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getDisabledStaffsOrStaffById.fulfilled, (state, action) => {
    state.loading = false;
    state.disabledStaffs = action.payload;
  });
  builder.addCase(getDisabledStaffsOrStaffById.rejected, (state, action) => {
    state.loading = false;
  });

  // create or update staff child
  builder.addCase(createOrUpdateStaffChild.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(createOrUpdateStaffChild.fulfilled, (state, action) => {
    state.loading = false;
    // if (state.staffChildrens?.length) {
    //   state.staffChildrens = [action.payload];
    // } else {
    //   state.staffChildrens = state.staffChildrens?.map((el) => {
    //     if (el.id === action.payload.id) {
    //       return action.payload;
    //     }
    //     return el;
    //   });
    // }
  });
  builder.addCase(createOrUpdateStaffChild.rejected, (state, action) => {
    state.loading = false;
  });

  // get disabled staff by id
  builder.addCase(getStaffChildByStaffId.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getStaffChildByStaffId.fulfilled, (state, action) => {
    state.loading = false;
    state.staffChildrens = action.payload;
  });
  builder.addCase(getStaffChildByStaffId.rejected, (state, action) => {
    state.loading = false;
  });
};

const PensionExtraReducers = (builder) => {
  // upsert pension acounting
  builder.addCase(upsertPensionAccounting.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(upsertPensionAccounting.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(upsertPensionAccounting.rejected, (state, action) => {
    state.loading = false;
  });

  // get pension accounting by id
  builder.addCase(getPensionAccountingById.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getPensionAccountingById.fulfilled, (state, action) => {
    state.loading = false;
    state.pensionAccountings = action.payload;
  });
  builder.addCase(getPensionAccountingById.rejected, (state, action) => {
    state.loading = false;
  });

  // get pension accounting by id
  builder.addCase(getPensioners.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getPensioners.fulfilled, (state, action) => {
    state.loading = false;
    state.pensioners = action.payload;
  });
  builder.addCase(getPensioners.rejected, (state, action) => {
    state.loading = false;
  });
};

const AdminExtraReducers = (builder) => {
  // upsert user ==================
  builder.addCase(upsertUser.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(upsertUser.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(upsertUser.rejected, (state, action) => {
    state.loading = false;
  });

  // get role option
  builder.addCase(getRoleOption.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getRoleOption.fulfilled, (state, action) => {
    state.loading = false;
    state.roleOption = action.payload;
  });
  builder.addCase(getRoleOption.rejected, (state, action) => {
    state.loading = false;
  });

  // get users option
  builder.addCase(getUsers.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getUsers.fulfilled, (state, action) => {
    state.loading = false;
    state.users = action.payload;
  });
  builder.addCase(getUsers.rejected, (state, action) => {
    state.loading = false;
  });

  // get acl admin
  builder.addCase(getAclAdmin.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getAclAdmin.fulfilled, (state, action) => {
    state.loading = false;
    state.aclAdmin = action.payload;
  });
  builder.addCase(getAclAdmin.rejected, (state, action) => {
    state.loading = false;
  });

  // upsert acl
  builder.addCase(upsertAcl.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(upsertAcl.fulfilled, (state, action) => {
    state.loading = false;
    state.aclAdmin = action.payload;
  });
  builder.addCase(upsertAcl.rejected, (state, action) => {
    state.loading = false;
  });

  // get acl admin by id
  builder.addCase(getAclAdminById.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getAclAdminById.fulfilled, (state, action) => {
    state.loading = false;
    state.aclAdminByRoleId = action.payload;
  });
  builder.addCase(getAclAdminById.rejected, (state, action) => {
    state.loading = false;
  });

  // upsert role option
  builder.addCase(upsertRole.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(upsertRole.fulfilled, (state, action) => {
    state.loading = false;
    // if (action.payload?.id) {
    //   state.roleOption = state.roleOption?.map((el) => {
    //     if (el.id === action.payload?.id) {
    //       return action.payload;
    //     }
    //     return el;
    //   });
    // } else if (action.payload?.name) {
    //   state.roleOption = [...state.roleOption, action.payload];
    // }
  });
  builder.addCase(upsertRole.rejected, (state, action) => {
    state.loading = false;
  });

  // upsert department option
  builder.addCase(upsertDepartment.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(upsertDepartment.fulfilled, (state, action) => {
    state.loading = false;
    // if (action.payload?.id) {
    //   state.departmentOption = state.departmentOption?.map((el) => {
    //     if (el.id === action.payload?.id) {
    //       return action.payload;
    //     }
    //     return el;
    //   });
    // } else if (action.payload?.name) {
    //   state.departmentOption = [...state.departmentOption, action.payload];
    // }
  });
  builder.addCase(upsertDepartment.rejected, (state, action) => {
    state.loading = false;
  });

  // upsert rank
  builder.addCase(upsertRank.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(upsertRank.fulfilled, (state, action) => {
    state.loading = false;
    // if (action.payload?.id) {
    //   state.rankOption = state.rankOption?.map((el) => {
    //     if (el.id === action.payload?.id) {
    //       return action.payload;
    //     }
    //     return el;
    //   });
    // } else if (action.payload?.name) {
    //   state.rankOption = [...state.rankOption, action.payload];
    // }
  });
  builder.addCase(upsertRank.rejected, (state, action) => {
    state.loading = false;
  });

  // upsert gender
  builder.addCase(upsertGender.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(upsertGender.fulfilled, (state, action) => {
    state.loading = false;
    // if (action.payload?.id) {
    //   state.genderOption = state.genderOption?.map((el) => {
    //     if (el.id === action.payload?.id) {
    //       return action.payload;
    //     }
    //     return el;
    //   });
    // } else if (action.payload?.name) {
    //   state.genderOption = [...state.genderOption, action.payload];
    // }
  });
  builder.addCase(upsertGender.rejected, (state, action) => {
    state.loading = false;
  });

  // upsert marker
  builder.addCase(upsertMarker.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(upsertMarker.fulfilled, (state, action) => {
    state.loading = false;
    // if (action.payload?.id) {
    //   state.markerOption = state.markerOption?.map((el) => {
    //     if (el.id === action.payload?.id) {
    //       return action.payload;
    //     }
    //     return el;
    //   });
    // } else if (action.payload?.name) {
    //   state.markerOption = [...state.markerOption, action.payload];
    // }
  });
  builder.addCase(upsertMarker.rejected, (state, action) => {
    state.loading = false;
  });

  // upsert retention
  builder.addCase(upsertRetention.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(upsertRetention.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(upsertRetention.rejected, (state, action) => {
    state.loading = false;
  });

  // upsert additional payment
  builder.addCase(upsertAdditionalPayment.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(upsertAdditionalPayment.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(upsertAdditionalPayment.rejected, (state, action) => {
    state.loading = false;
  });

  // upsert staff status option
  builder.addCase(upsertStaffStatus.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(upsertStaffStatus.fulfilled, (state, action) => {
    state.loading = false;
    // if (action.payload?.id) {
    //   state.staffStatus = state.staffStatus?.map((el) => {
    //     if (el.id === action.payload?.id) {
    //       return action.payload;
    //     }
    //     return el;
    //   });
    // } else if (action.payload?.name) {
    //   state.staffStatus = [...state.staffStatus, action.payload];
    // }
  });
  builder.addCase(upsertStaffStatus.rejected, (state, action) => {
    state.loading = false;
  });

  // upsert desmissial type
  builder.addCase(upsertDismissalType.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(upsertDismissalType.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(upsertDismissalType.rejected, (state, action) => {
    state.loading = false;
  });

  // upsert disciplinary action
  builder.addCase(upsertDisciplinaryAction.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(upsertDisciplinaryAction.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(upsertDisciplinaryAction.rejected, (state, action) => {
    state.loading = false;
  });

  // upsert pension type
  builder.addCase(upsertPensionType.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(upsertPensionType.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(upsertPensionType.rejected, (state, action) => {
    state.loading = false;
  });

  // upsert document type
  builder.addCase(upsertDocumentType.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(upsertDocumentType.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(upsertDocumentType.rejected, (state, action) => {
    state.loading = false;
  });

  // upsert deceased staff groups
  builder.addCase(upsertDeceasedStaffGroups.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(upsertDeceasedStaffGroups.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(upsertDeceasedStaffGroups.rejected, (state, action) => {
    state.loading = false;
  });

  // upsert disabled staff groups
  builder.addCase(upsertDisabledStaffGroups.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(upsertDisabledStaffGroups.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(upsertDisabledStaffGroups.rejected, (state, action) => {
    state.loading = false;
  });

  // upsert staff financial aid
  builder.addCase(upsertStaffFinancialAid.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(upsertStaffFinancialAid.fulfilled, (state, action) => {
    state.loading = false;
    // if (action.payload?.id) {
    //   state.staffFinancialAid = state.staffFinancialAid?.map((el) => {
    //     if (el.id === action.payload?.id) {
    //       return action.payload;
    //     }
    //     return el;
    //   });
    // } else if (action.payload?.name) {
    //   state.staffFinancialAid = [...state.staffFinancialAid, action.payload];
    // }
  });
  builder.addCase(upsertStaffFinancialAid.rejected, (state, action) => {
    state.loading = false;
  });

  // upsert vacation types
  builder.addCase(upsertVacationOption.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(upsertVacationOption.fulfilled, (state, action) => {
    state.loading = false;
    // if (action.payload?.id) {
    //   state.vacationOption = state.vacationOption?.map((el) => {
    //     if (el.id === action.payload?.id) {
    //       return action.payload;
    //     }
    //     return el;
    //   });
    // } else if (action.payload?.name) {
    //   state.vacationOption = [...state.vacationOption, action.payload];
    // }
  });
  builder.addCase(upsertVacationOption.rejected, (state, action) => {
    state.loading = false;
  });

  // upsert trip daily
  builder.addCase(upsertTripDailyPayOption.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(upsertTripDailyPayOption.fulfilled, (state, action) => {
    state.loading = false;
    // if (action.payload?.record_id) {
    //   state.tripDailyPayOptions = state.tripDailyPayOptions?.map((el) => {
    //     if (el.record_id === action.payload?.record_id) {
    //       return action.payload;
    //     }
    //     return el;
    //   });
    // } else if (action.payload?.name) {
    //   state.tripDailyPayOptions = [
    //     ...state.tripDailyPayOptions,
    //     action.payload,
    //   ];
    // }
  });
  builder.addCase(upsertTripDailyPayOption.rejected, (state, action) => {
    state.loading = false;
  });

  // upsert jobtitle
  builder.addCase(upsertJobtitle.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(upsertJobtitle.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(upsertJobtitle.rejected, (state, action) => {
    state.loading = false;
  });

  // upsert countries
  builder.addCase(upsertCountries.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(upsertCountries.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(upsertCountries.rejected, (state, action) => {
    state.loading = false;
  });

  // upsert region
  builder.addCase(upsertRegion.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(upsertRegion.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(upsertRegion.rejected, (state, action) => {
    state.loading = false;
  });

  // upsert pension awards
  builder.addCase(upsertPensionAwards.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(upsertPensionAwards.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(upsertPensionAwards.rejected, (state, action) => {
    state.loading = false;
  });

  // upsert rankbonus
  builder.addCase(upsertRankbonus.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(upsertRankbonus.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(upsertRankbonus.rejected, (state, action) => {
    state.loading = false;
  });

  // get rankbonus
  builder.addCase(getRankbonus.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getRankbonus.fulfilled, (state, action) => {
    state.loading = false;
    state.rankbonus = action.payload;
  });
  builder.addCase(getRankbonus.rejected, (state, action) => {
    state.loading = false;
  });

  // upsert jobtitl amount
  builder.addCase(upsertJobtitleAmout.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(upsertJobtitleAmout.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(upsertJobtitleAmout.rejected, (state, action) => {
    state.loading = false;
  });

  // get jobtitle amount
  builder.addCase(getJobtitleAmount.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getJobtitleAmount.fulfilled, (state, action) => {
    state.loading = false;
    state.jobtitleAmount = action.payload;
  });
  builder.addCase(getJobtitleAmount.rejected, (state, action) => {
    state.loading = false;
  });
};

const StorageExtraReducers = (builder) => {
  // get doc by id
  builder.addCase(getDocById.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getDocById.fulfilled, (state, action) => {
    state.loading = false;
    state.docData = action.payload;
  });
  builder.addCase(getDocById.rejected, (state, action) => {
    state.loading = false;
  });

  // get file by id
  builder.addCase(getFileById.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getFileById.fulfilled, (state, action) => {
    state.loading = false;
    // state.file = action.payload;
  });
  builder.addCase(getFileById.rejected, (state, action) => {
    state.loading = false;
  });
};

const PaymentExtraReducers = (builder) => {
  // get transaction salary intermediate
  builder.addCase(getTransactionSalaryIntermediate.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(
    getTransactionSalaryIntermediate.fulfilled,
    (state, action) => {
      state.loading = false;
      state.taransactionSalaryIntermediate = action.payload;
    }
  );
  builder.addCase(
    getTransactionSalaryIntermediate.rejected,
    (state, action) => {
      state.loading = false;
    }
  );

  // post transaction salary intermediate accept
  builder.addCase(
    postTransactionSalaryIntermediateAccept.pending,
    (state, action) => {
      state.loading = true;
    }
  );
  builder.addCase(
    postTransactionSalaryIntermediateAccept.fulfilled,
    (state, action) => {
      state.loading = false;
      state.taransactionSalaryIntermediateDoc = action.payload;
    }
  );
  builder.addCase(
    postTransactionSalaryIntermediateAccept.rejected,
    (state, action) => {
      state.loading = false;
    }
  );

  // post transaction salary intermediate accept
  builder.addCase(
    getTransactionSalaryIntermediateBankData.pending,
    (state, action) => {
      state.loading = true;
    }
  );
  builder.addCase(
    getTransactionSalaryIntermediateBankData.fulfilled,
    (state, action) => {
      state.loading = false;
      state.taransactionSalaryIntermediateBankData = action.payload;
    }
  );
  builder.addCase(
    getTransactionSalaryIntermediateBankData.rejected,
    (state, action) => {
      state.loading = false;
    }
  );

  // post transaction salary intermediate decline
  builder.addCase(
    postTransactionSalaryIntermediateDecline.pending,
    (state, action) => {
      state.loading = true;
    }
  );
  builder.addCase(
    postTransactionSalaryIntermediateDecline.fulfilled,
    (state, action) => {
      state.loading = false;
      state.taransactionSalaryIntermediate = [];
      state.taransactionSalaryIntermediateDoc = null;
    }
  );
  builder.addCase(
    postTransactionSalaryIntermediateDecline.rejected,
    (state, action) => {
      state.loading = false;
    }
  );

  // create staff additional payment
  builder.addCase(createStaffAdditionalPayment.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(createStaffAdditionalPayment.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(createStaffAdditionalPayment.rejected, (state, action) => {
    state.loading = false;
  });

  // get additional payments
  builder.addCase(getAdditionalPayments.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getAdditionalPayments.fulfilled, (state, action) => {
    state.loading = false;
    state.additionalPayments = action.payload;
  });
  builder.addCase(getAdditionalPayments.rejected, (state, action) => {
    state.loading = false;
  });

  // create staff retetion
  builder.addCase(createStaffRetention.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(createStaffRetention.fulfilled, (state, action) => {
    state.loading = false;
  });
  builder.addCase(createStaffRetention.rejected, (state, action) => {
    state.loading = false;
  });

  // get retetions
  builder.addCase(getRetentions.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getRetentions.fulfilled, (state, action) => {
    state.loading = false;
    state.retentions = action.payload;
  });
  builder.addCase(getRetentions.rejected, (state, action) => {
    state.loading = false;
  });
};

export function globalStateExtraReducers(builder) {
  ////////    Swagger AUTH    ////////
  AuthExtraReducers(builder);

  ////////    Swagger COMMON    ////////
  CommonExtraReducers(builder);

  ////////    Swagger HR    ////////
  HrExtraReducers(builder);

  ////////    Swagger PENSION    ////////
  PensionExtraReducers(builder);

  ////////    Swagger ADMIN    ////////
  AdminExtraReducers(builder);

  ////////    Swagger STORAGE    ////////
  StorageExtraReducers(builder);

  ////////    Swagger PAYMENT    ////////
  PaymentExtraReducers(builder);
}
