import { useSelector } from "react-redux";
import "./App.css";
import "./i18n";
import RoutesCom from "./router/router";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();
  const user = useSelector(({ globalState }) => globalState.user);
  let window_ = window;

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      if (!window_.location.href.includes("/login/eds")) {
        navigate("/login");
      }
    }
    // @ts-ignore
  }, [window_.location.href, navigate, user]);
  return (
    <div className="bg-[rgba(208,221,240,1)]">
      <RoutesCom />
    </div>
  );
}

export default App;
