import { Spin } from 'antd'
import React from 'react'

function Loader() {
  return (
    <div className='fixed top-0 left-0 w-full h-[100vh] grid place-content-center z-[999999999]'>
      <Spin size='large'/>
    </div>
  )
}

export default Loader