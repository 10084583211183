//////////////////////       Swagger AUTH          //////////////////////

import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosBaseRequest } from "../../../utils/axiosBaseUrl";
import { handleBinaryData } from "../../../utils/globalFunction";

// get document by id
export const getDocById = createAsyncThunk(
  "getDocById",
  async (body, { dispatch }) => {
    try {
      const { data } = await axiosBaseRequest.get(
        `storage/v1/files?doc_id=${body.doc_id}`
      );
      // if (body.print) {
      //   data.files?.map((el) => {
      //     dispatch(getFileById({ file_id: data.file_id }));
      //   })
      // }
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get file by id
export const getFileById = createAsyncThunk(
  "getFileById",
  async ({ file_id }) => {
    try {
      const { data } = await axiosBaseRequest.get(
        `storage/v1/file?file_id=${file_id}`,
        {
          responseType: "blob",
        }
      );
      handleBinaryData(data, "file.pdf", "application/pdf");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);
