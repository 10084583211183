//////////////////////       Swagger PENSION          //////////////////////

import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosRequest } from "../../../utils/axiosURL";
import { message } from "antd";

// pension #########################

// upsert pension acounting
export const upsertPensionAccounting = createAsyncThunk(
  "upsertPensionAccounting",
  async (body) => {
    try {
      const { data } = await axiosRequest.post("pension/v1/pension", body, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      message.success("Запрос успешен");
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get pension accounting by id
export const getPensionAccountingById = createAsyncThunk(
  "getPensionAccountingById",
  async ({ id, days }) => {
    try {
      const { data } = await axiosRequest.get(
        `pension/v1/pension?id=${id}&days=${days}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// get pensions
export const getPensioners = createAsyncThunk(
  "getPensioners",
  async ({ type_id, department_id }) => {
    try {
      const { data } = await axiosRequest.get(
        `pension/v1/pension/all?department_id=${department_id}&type_id=${type_id}`
      );
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);
