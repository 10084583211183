import CryptoJS from "crypto-js";
export function encruption() {
  var inputValue = process.env.REACT_APP_X_APP_TOKEN;
  // console.log("Значение поля: " + inputValue);

  let iv = CryptoJS.lib.WordArray.random(16);

  //   let str = process.env.REACT_APP_X_APP_TOKEN

  // Получаем временной штамп
  var ts = Math.floor(Date.now() / 1000);
  var apiTimestamp = ts.toString().slice(-10); // Приводим к 10-символьному формату

  inputValue += apiTimestamp;
  // console.log("p", process.env.REACT_APP_SECRET_KEY);
  
  var key = CryptoJS.enc.Base64?.parse(process.env.REACT_APP_SECRET_KEY);
  // console.log("key", key);

  let encrypted = CryptoJS.AES.encrypt(inputValue, key, { iv: iv });

  let joinedData = iv.clone().concat(encrypted.ciphertext);

  // Преобразуем в строку в формате Base64
  let joinedDataB64 = CryptoJS.enc.Base64.stringify(joinedData);

  // console.log("Зашифрованные данные:", joinedDataB64);
  return joinedDataB64;
}

// export function encruption() {
//     var inputValue = process.env.REACT_APP_X_APP_TOKEN;
//     console.log("Значение поля: " + inputValue);

//     let iv = CryptoJS?.lib.WordArray.random(16);

//     //   let str = process.env.REACT_APP_X_APP_TOKEN

//     // Получаем временной штамп
//     var ts = Math.floor(Date.now() / 1000);
//     var apiTimestamp = ts.toString().slice(-10); // Приводим к 10-символьному формату

//     inputValue += apiTimestamp;
//     console.log("p", process.env.REACT_APP_SECRET_KEY);
//     var key = CryptoJS?.enc.Base64.parse(process.env.REACT_APP_SECRET_KEY);

//     let encrypted = CryptoJS?.AES.encrypt(inputValue, key, { iv: iv });

//     let joinedData = iv.clone().concat(encrypted.ciphertext);

//     // Преобразуем в строку в формате Base64
//     let joinedDataB64 = CryptoJS?.enc.Base64.stringify(joinedData);

//     console.log("Зашифрованные данные:", joinedDataB64);
//     return joinedDataB64;
//   }
