import { jwtDecode } from "jwt-decode";

function saveToken(
  access_token
  // refreshToken
) {
  localStorage.setItem("token", access_token);
  // localStorage.setItem("refresh_token", refreshToken);
}

function getToken() {
  try {
    return jwtDecode(localStorage.getItem("token"));
  } catch (error) {}
}

function destroyToken() {
  window.location.href = "/login";
  localStorage.removeItem("token");
  // localStorage.removeItem("refresh_token");
}

function refreshToken(access_token) {
  destroyToken();
  localStorage.setItem("token", access_token);
}
export { saveToken, getToken, destroyToken, refreshToken };
